// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  days: {
    sunday: `Sunday`,
    monday: `Monday`,
    tuesday: `Tuesday`,
    wednesday: `Wednesday`,
    thursday: `Thursday`,
    friday: `Friday`,
    saturday: `Saturday`,

  },
  months: {
    january: `January`,
    february: `February`,
    march: `March`,
    april: `April`,
    may: `May`,
    june: `June`,
    july: `July`,
    august: `August`,
    september: `September`,
    october: `October`,
    november: `November`,
    december: `December`,
  },
  myEvents: {
    wishes: `Wishes`,
    noEvent: `No Event`,
    view: `View`,
    edit: `Edit`,
    location: `Location`,
    invitationLink: `Invitation Link`,
    preview: `Preview your share page`,
    editShare: `Edit your reserved wishes`,
    shareEvent: `Share your event:`,
    shareEventEdit: `Edit your Reserved:`,
    copy: `copy share link`,
    invited: `You are invited!`,
  },
  mainPage: {
    myWishes: `My Wishes`,
    myEvents: `My Events`,
    calendar: `Calendar`,
  },
  calendar: {
    allDay: 'All day',
    addWish: 'Add New Wish',
    editEvent: 'Edit Event',
    addEvent: 'Add Event',
    wishes: 'Wishes',
    wishesChoose: 'Choose from Wishes',
    start: 'Satrt date',
    end: 'End date',
    title: 'Title',
    location: 'Location',
    description: 'Description',
    cancel: 'Cancel',
    add: 'Add',
    today: 'Today',
    update: 'Update',
    deleteEvent: 'Delete Event',
  },
  sharePage: {
    deleted: 'This event is deleted!',
    expired: 'This event is expired!',
    reserve: 'Reserve',
    unreserve: 'Unreserve',
    clickReserve: 'Click to reserve',
    reserved: 'Reserved',
    reservedBy: 'Reserved by',
    buyFrom: 'buy from',
    chooseFrom: 'You can choose from the wishes below:',
  },
  loginPage: {
    signIn: 'Sign in/up to',
    logout: 'Logout',
  },
  myWishes: {
    deleteBtn: 'Delete',
    clear: 'Clear',
    newWish: 'New Wish',
    noWish: 'No Wish',
    price: 'Price',
    seller: 'Seller',
    status: 'Status',
    currency: 'Currency',
    url: 'Web URL',
    imageDisable: 'Apply disable Image',
    name: 'Gift Name',
    search: 'Search Gift Names...',
    items: 'items',
    edit: 'Edits',
    fetch: 'Fetch',
    editWish: 'Edit your wish',
    createWish: 'Create a new wish',
    delete: 'Are you sure want to delete?',
    create: 'Create Wish',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
  },
  premium: {
    upgrade: 'Upgrade to Premium for Unlimited Access!',
    upgrade1: 'Upgrade to Premium for unlimited access to our service. Click the',
    upgrade2: 'button below to be the first to know when our premium packages are available. Thank you for your continued support.',
    notify: 'Notify Me',
  },
  message: {
    comingSoon: 'Thank you, you will be informed soon!',
    tryLater: 'please try later!',
    waitMoment: 'please wait a moment!',
    wentWrong: 'Something went wrong, try later !',
    premium: 'please upgrade to premium!',
    invalidLink: 'not a valid link!',
    invalidRequest: 'not a valid Request!',
    createWish: 'Create a new wish success !',
    updateWish: 'Update the wish success !',
    updateReserve: 'Reserve the wish success !',
    removeWish: 'successfuly removed.',
    noSupport: 'Not Supported!',
    offline: 'you are offline!',
    updateEvent: 'Update Success!',
    createEvent: 'Create Success!',
    isReserved: 'Sorry, you can not change this event.',
  },
  terms: {
    n1: '',
    n2: '',
    n3: '',
    n4: '',
    n5: '',
    n6: '',
    n7: '',
    n8: '',
    n9: '',
    n10: '',
    n11: '',
    n12: '',
    n13: '',
    n14: '',
    n15: '',
    n16: '',
    n17: '',
    n18: '',
    n19: '',
    n20: '',
    n21: '',
  },
  blog: {
    add: ''
  }

};

export default en;
