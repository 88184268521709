// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  days: {
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',

  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
  myEvents: {
    wishes: `Deseos`,
    noEvent: `Sin Evento`,
    view: `Ver`,
    edit: `Editar`,
    location: `Ubicación`,
    invitationLink: `Enlace de Invitación`,
    preview: `Compartir Previsualice `,
    editShare: `Reservados Editar`,
    shareEvent: `Evento Compartir:`,
    shareEventEdit: `Reserva Editar:`,
    copy: `copiar enlace`,
    invited: `¡Está invitado!`,
  },

  mainPage: {
    myWishes: 'Mis Deseos',
    myEvents: 'Mis Eventos',
    calendar: 'Calendario',
  },
  calendar: {
    allDay: 'Todo el día',
    addWish: 'Agregar Nueva Deseo',
    editEvent: 'Editar Evento',
    addEvent: 'Añadir Evento',
    wishes: 'Deseos',
    wishesChoose: 'Elige de los Deseos',
    start: 'Fecha de Inicio',
    end: 'Fecha de Finalización',
    title: 'Título',
    location: 'Ubicación',
    description: 'Descripción',
    cancel: 'Cancelar',
    add: 'Añadir',
    today: 'Hoy',
    update: 'Actualizar',
    deleteEvent: 'Eliminar Evento',
  },
  sharePage: {
    deleted: 'Este evento está eliminado!',
    expired: '¡Este evento ha caducado!',
    reserve: 'Reservar',
    unreserve: 'Desmarcar',
    clickReserve: 'Haz clic para reservar',
    reserved: 'Reservado',
    reservedBy: 'Reservado por',
    buyFrom: 'Comprar de',
    chooseFrom: 'Puedes elegir entre los siguientes deseos:',
  },
  loginPage: {
    signIn: 'Iniciar sesión en',
    logout: 'Logout',
  },
  myWishes: {
    deleteBtn: 'Eliminar',
    clear: 'Limpiar',
    newWish: 'Nuevo deseo',
    noWish: 'No hay deseos',
    price: 'Precio',
    seller: 'Vendedor',
    status: 'Estado',
    currency: 'Moneda',
    url: 'URL del sitio web',
    imageDisable: 'Aplicar imagen deshabilitada',
    name: 'Nombre del regalo',
    search: 'Buscar nombres de regalos...',
    items: 'artículos',
    edit: 'Editar',
    fetch: 'Buscar',
    editWish: 'Editar su deseo',
    createWish: 'Crear un nuevo deseo',
    delete: '¿Estás seguro de que quieres eliminar?',
    create: 'Crear deseo',
    saveChanges: 'Guardar cambios',
    cancel: 'Cancelar',
  },
  premium: {
    upgrade: '¡Actualízate a Premium para acceso ilimitado!',
    upgrade1: 'Actualízate a Premium para acceso ilimitado a nuestro servicio. Haz clic en el botón de abajo para ser el primero en saber cuándo estarán disponibles nuestros paquetes premium. Gracias por tu continuo apoyo.',
    upgrade2: 'Notificarme',
  },
  message: {
    comingSoon: '¡Gracias, se te informará pronto!',
    tryLater: '¡Por favor, intenta de nuevo más tarde!',
    waitMoment: '¡Por favor, espera un momento!',
    wentWrong: '¡Algo salió mal, inténtalo de nuevo más tarde!',
    premium: "¡Por favor, actualice a Premium!",
    invalidLink: '¡Enlace no válido!',
    createWish: '¡Creación de nuevo deseo exitosa!',
    updateWish: '¡Actualización del deseo exitosa!',
    updateReserve: '¡Reserva del deseo exitosa!',
    removeWish: 'Eliminado exitosamente.',
    noSupport: '¡No es compatible!',
    offline: '¡Estás desconectado!',
    updateEvent: '¡Actualización exitosa!',
    createEvent: '¡Creación exitosa!',
    isReserved: 'Lo siento, no puedes cambiar este evento.',
  },
};
export default es;
