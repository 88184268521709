import { m } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// @mui
import {
  Grid,
  Container,
  Stack,
  Typography,
  Button,
  useTheme,
} from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import LightModeIcon from "@mui/icons-material/LightMode";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import { MotionContainer } from "../../components/animate";
import { SkeletonPostItem } from "../../components/skeleton";
import { useSettingsContext } from "../../components/settings";
// sections

// _mock_
import { BlogPostCard, BlogPostsSearch } from "../../sections/blog";
import SimpleFooter from "../../components/footer/SimpleFooter";
import { _blog } from "../../_mock/blog/_blog";
import { IconButtonAnimate } from "../../components/animate";

// ----------------------------------------------------------------------

const OPTIONS = ["light", "dark"] as const;
const varFade = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export default function HomePgae() {
  const { themeMode, onChangeMode, themeStretch } = useSettingsContext();
  const theme = useTheme();
  const isMobile = useResponsive("down", "sm");
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const navigate = useNavigate();
  // const handleBack = () => navigate('/');
  const handleBack = () => {
    window.location.href = "/";
  };
  const posts = _blog;
  // const posts = _blog.filter((post) =>
  //   moment(post.id, "YYYYMMDD").isBefore(moment())
  // );

  const handleMode = (event: any) => {
    if (themeMode === "light") {
      event.target.value = "dark";
    } else {
      event.target.value = "light";
    }
    return onChangeMode(event);
  };

  return (
    <>
      <Helmet>
        <title>Homepage | AmiroAmir </title>
      </Helmet>
      <Stack
        mb={5}
        direction="row"
        alignItems="center"
        justifyContent="right"
        sx={{
          position: "fixed",
          zIndex: "1",
          background: theme.palette.background.paper,
          opacity: "0.8",
          width: "100%",
          padding: "15px",
        }}
      >
        <Typography component="div" sx={{ display: "flex" }}>
          <Typography
            component="div"
            sx={{ verticalAlign: "middle", margin: "auto", float: "right" }}
          >
            {OPTIONS.map((mode) =>
              themeMode !== mode ? (
                <IconButtonAnimate
                  aria-label="mode"
                  key={mode}
                  onClick={handleMode}
                  sx={{
                    width: 40,
                    height: 40,
                    marginRight: { xs: "0px", sm: "10px" },
                  }}
                >
                  {mode === "light" && <LightModeIcon />}
                  {mode !== "light" && <NightsStayIcon />}
                </IconButtonAnimate>
              ) : null
            )}
          </Typography>
        </Typography>
      </Stack>
      <Container
        maxWidth={themeStretch ? false : "lg"}
        sx={{
          height: "100%",
          textAlign: "center",
          margin: "auto",
          fontSize: "10vw",
          padding: "35vh 10px",
          opacity: "0.9",
        }}
      >
        <m.div
          variants={varFade}
          initial="out"
          animate="in"
          transition={{ duration: 3 }}
        >
          AMIRoAMIR.COM
        </m.div>
      </Container>
      <SimpleFooter />
    </>
  );
}
