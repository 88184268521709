import { photoURL } from "../../../utils/photoURL";

export const _20230401 = {
  id: '20230401',
  date: '20230401',
  title: 'The Best Gift Ideas for Any Location and Person',
  slug: 'gift-idea',
  description:
    'Giving a gift is one of the beautiful human actions done for reasons such as commemorating friendship, appreciation of efforts, wedding anniversary and birthday. This work is done without any financial concern from the gift recipient. However, it is expected that in the future, this work will be compensated by the recipient by giving a reciprocal gift.',
  cover: photoURL('3d0865fa-f4ea-400b-4483-fd2ec77de800'),
  createdAt: '2023-05-20',
  author: {
    name: 'Adrian Demian',
    avatarUrl: photoURL('6db378c9-66e4-423b-ae08-0e5f63769200'),
  },
  tags: ['Analytics', 'Google'],
};
