// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const BASE_URL = '';
export const PATH_IMAGES = `${BASE_URL}assets2/images`;
export const PATH_ICONS = `${BASE_URL}assets2/icons`;
export const PATH_BACKGROUND = `${BASE_URL}assets2/background`;
export const PATH_ILLUSTRATIONS = `${BASE_URL}assets2/illustrations`;
export const PATH_BLOG_ASSETS = `${BASE_URL}assets2/blog`;

const ROOTS_BLOG = `${BASE_URL}`;

// ----------------------------------------------------------------------

export const PATH_BLOG = {
  blog: ROOTS_BLOG,
  post: {
    root: path(ROOTS_BLOG, '/:slug'),
    view: (slug: string) => path(ROOTS_BLOG, `/${slug}`),
  },
};

export const PATH_PAGE = {
  public: `${BASE_URL}/public/:id`,
  terms: `${BASE_URL}/terms`,
  page403: '/403',
  page404: '/404',
  page500: '/500',
};
