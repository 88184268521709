// i18n
import "./locales/i18n";
// scroll bar
import "simplebar/src/simplebar.css";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
// -----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";
import { useEffect } from "react";

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

// ----------------------------------------------------------------------

export default function App() {
  // Function to clear complete cache data
  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  // };
  // useEffect(() => {
  //   clearCacheData();
  // });

  return (
    // <AuthProvider>
    <HelmetProvider>
      <SettingsProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <Router />
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </BrowserRouter>
        </LocalizationProvider>
      </SettingsProvider>
    </HelmetProvider>
    // </AuthProvider>
  );
}
