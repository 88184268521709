import { Navigate, useRoutes } from "react-router-dom";
// layouts
import CompactLayout from "../layouts/compact";
// pages
import { Page404, BlogPostPage, BlogPostsPage } from "./elements";
import { BASE_URL, PATH_BLOG } from "./paths";
import HomePgae from "../pages/homepage/HomePgae";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: BASE_URL,
      element: <HomePgae />,
    },
    // {
    //   path: PATH_BLOG.blog,
    //   children: [
    //     { element: <BlogPostsPage />, index: true },
    //     { path: PATH_BLOG.post.root, element: <BlogPostPage /> },
    //   ],
    // },

    // {
    //   element: <CompactLayout />,
    //   children: [{ path: `${BASE_URL}/404`, element: <Page404 /> }],
    // },
    { path: `${BASE_URL}/*`, element: <Navigate to={`${BASE_URL}`} /> },
    { path: '/*', element: <Navigate to={`${BASE_URL}`} /> },
    { path: '/', element: <Navigate to={BASE_URL} /> },
  ]);
}
