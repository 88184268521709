import { useNavigate } from "react-router-dom";
// @mui
import { Typography, Stack, Box, Container, useTheme } from "@mui/material";
//
import { PAGE_NAME, YEAR } from "../../config-global";

// ----------------------------------------------------------------------

export default function SimpleFooter() {
  const navigate = useNavigate();
  // const handleBack = () => navigate('/');
  const handleBack = () => {
    window.location.href = "/";
  };
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "27px",
        textAlign: "center",
        position: "fixed",
        bgcolor: "background.default",
        bottom: "0px",
        left: "0px",
        right: "0px",
        borderTop: "1px solid #8080803d",
        background: theme.palette.background.paper,
      }}
    >
      <Container>
        <Typography variant="caption" component="span" sx={{ margin: "auto" }}>
          Copyright © {`${YEAR}`}
          <Typography
            variant="subtitle2"
            onClick={handleBack}
            sx={{ fontWeight: "bold", cursor: "pointer" }}
            component="span"
          >
            {` ${PAGE_NAME}`}
          </Typography>
        </Typography>
      </Container>
    </Box>
  );
}
