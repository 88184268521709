// @mui
import { enUS, frFR, zhCN, viVN, arSA, deDE, esES } from '@mui/material/locale';
import { PATH_ICONS } from '../routes/paths';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: `${PATH_ICONS}/flags/ic_flag_en.svg`,
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: `${PATH_ICONS}/flags/ic_flag_fr.svg`,
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: `${PATH_ICONS}/flags/ic_flag_de.svg`,
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: `${PATH_ICONS}/flags/ic_flag_es.svg`,
  },
  // {
  //   label: 'Vietnamese',
  //   value: 'vi',
  //   systemValue: viVN,
  //   icon: `${PATH_ICONS}/flags/ic_flag_vn.svg`,
  // },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: zhCN,
  //   icon: `${PATH_ICONS}/flags/ic_flag_cn.svg`,
  // },
  // {
  //   label: 'Arabic (Sudan)',
  //   value: 'ar',
  //   systemValue: arSA,
  //   icon: `${PATH_ICONS}/flags/ic_flag_sa.svg`,
  // },
];

export const defaultLang = allLangs[0]; // English
