
// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const de = {
  days: {
    sunday: 'Sonntag',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',

  },
  months: {
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
  },
  myEvents: {
    wishes: `Wünsche`,
    noEvent: `Keine Veranstaltung`,
    view: `Ansehen`,
    edit: `Bearbeiten`,
    location: `Ort`,
    invitationLink: `Einladungslink`,
    preview: `Freigabeseite Vorschau`,
    editShare: `reservierten Wünsche bearbeiten`,
    shareEvent: `Veranstaltung Teilen :`,
    shareEventEdit: `Reservierung Bearbeiten:`,
    copy: `Freigabe-Link kopieren`,
    invited: `Sie sind eingeladen!`,
  },

  mainPage: {
    myWishes: 'Meine Wünsche',
    myEvents: 'Meine Ereignisse',
    calendar: 'Kalender',
  },
  calendar: {
    allDay: 'Ganztägig',
    addWish: 'Neuen Wunsch hinzufügen',
    editEvent: 'Event bearbeiten',
    addEvent: 'Event hinzufügen',
    wishes: 'Wünsche',
    wishesChoose: 'Aus Wünschen auswählen',
    start: 'Startdatum',
    end: 'Enddatum',
    title: 'Titel',
    location: 'Ort',
    description: 'Beschreibung',
    cancel: 'Abbrechen',
    add: 'Hinzufügen',
    today: 'Heute',
    update: 'Aktualisieren',
    deleteEvent: 'Event löschen',
  },
  sharePage: {
    deleted: 'Diese Veranstaltung ist gelöscht!',
    expired: 'Dieses Ereignis ist abgelaufen!',
    reserve: 'Reservieren',
    unreserve: 'Rückgängig',
    clickReserve: 'Klicken Sie, um zu reservieren',
    reserved: 'Reserviert',
    reservedBy: 'Reserviert von',
    buyFrom: 'Kaufen von',
    chooseFrom: 'Sie können aus den folgenden Wünschen wählen:',
  },
  loginPage: {
    signIn: 'Anmelden bei',
    logout: 'Abmelden',
  },
  myWishes: {
    deleteBtn: 'Löschen',
    clear: 'Leeren',
    newWish: 'Neuer Wunsch',
    noWish: 'Kein Wunsch',
    price: 'Preis',
    seller: 'Verkäufer',
    status: 'Status',
    currency: 'Währung',
    url: 'Web-Adresse',
    imageDisable: 'Deaktivierte Bild anwenden',
    name: 'Geschenkname',
    search: 'Suche Geschenknamen...',
    items: 'Artikel',
    edit: 'Bearbeiten',
    fetch: 'Abrufen',
    editWish: 'Wunsch bearbeiten',
    createWish: 'Neuen Wunsch erstellen',
    delete: 'Möchten Sie wirklich löschen?',
    create: 'Wunsch erstellen',
    saveChanges: 'Änderungen speichern',
    cancel: 'Abbrechen',
  },
  premium: {
    upgrade: 'Upgrade auf Premium für uneingeschränkten Zugang!',
    upgrade1: 'Upgrade auf Premium für uneingeschränkten Zugang zu unserem Service. Klicken Sie auf die Schaltfläche unten, um als Erster informiert zu werden, wenn unsere Premium-Pakete verfügbar sind. Vielen Dank für Ihre fortgesetzte Unterstützung.',
    upgrade2: 'Benachrichtige mich',
    notify: 'Benachrichtigen Sie mich',
  },
  message: {
    comingSoon: 'Vielen Dank, Sie werden bald informiert!',
    tryLater: 'Bitte versuchen Sie es später!',
    waitMoment: 'Bitte warten Sie einen Moment!',
    wentWrong: 'Etwas ist schief gelaufen, bitte versuchen Sie es später!',
    premium: "Bitte upgraden Sie auf Premium!",
    invalidLink: 'Kein gültiger Link!',
    createWish: 'Neuer Wunsch erfolgreich erstellt!',
    updateWish: 'Aktualisierung des Wunsches erfolgreich!',
    updateReserve: 'Wunschreservierung erfolgreich!',
    removeWish: 'Erfolgreich entfernt.',
    noSupport: 'Nicht unterstützt!',
    offline: 'Sie sind offline!',
    updateEvent: 'Aktualisierung erfolgreich!',
    createEvent: 'Erstellung erfolgreich!',
    isReserved: 'Entschuldigung, Sie können dieses Event nicht ändern.',
  },
};

export default de;
