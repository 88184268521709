// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  days: {
    sunday: "Dimanche",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
  },
  months: {
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
  },
  myEvents: {
    wishes: `Voeux`,
    noEvent: `Aucun événement`,
    view: `Voir`,
    edit: `Modifier`,
    location: `Emplacement`,
    invitationLink: `Lien d'invitation`,
    preview: `Partage Aperçu`,
    editShare: `Réservés Modifier`,
    shareEvent: `événement Partagez:`,
    shareEventEdit: `Réservations Modifier:`,
    copy: `copier le lien de partage`,
    invited: `Vous êtes invité(e)!`,
  },
  mainPage: {
    myWishes: "Mes Vœux",
    myEvents: "Mes Événements",
    calendar: "Calendrier",
  },
  calendar: {
    allDay: 'Toute la journée',
    addWish: 'Ajouter un nouveau souhait',
    editEvent: `Modifier l'événement`,
    addEvent: 'Ajouter un événement',
    wishes: 'Souhaits',
    wishesChoose: 'Choisir parmi les souhaits',
    start: 'Date de début',
    end: 'Date de fin',
    title: 'Titre',
    location: 'Lieu',
    description: 'Description',
    cancel: 'Annuler',
    add: 'Ajouter',
    today: `Aujourd'hui`,
    update: 'Mettre à jour',
    deleteEvent: `Supprimer l'événement`,
  },
  sharePage: {
    deleted: 'Cet événement est supprimé!',
    expired: 'Cet événement est expiré!',
    reserve: 'Réserver',
    unreserve: 'Décochez',
    clickReserve: 'Cliquez pour réserver',
    reserved: 'Réservé',
    reservedBy: 'Réservé par',
    buyFrom: 'Acheter chez',
    chooseFrom: 'Vous pouvez choisir parmi les souhaits ci-dessous:',
  },
  loginPage: {
    signIn: 'Se connecter à',
    logout: 'Logout',
  },
  myWishes: {
    deleteBtn: 'Supprimer',
    clear: 'Effacer',
    newWish: 'Nouveau souhait',
    noWish: 'Aucun souhait',
    price: 'Prix',
    seller: 'Vendeur',
    status: 'Statut',
    currency: 'Devise',
    url: 'URL Web',
    imageDisable: `Appliquer l'image désactivée`,
    name: 'Nom du cadeau',
    search: 'Rechercher des noms de cadeaux...',
    items: 'articles',
    edit: 'Modifier',
    fetch: 'Récupérer',
    editWish: 'Modifier votre souhait',
    createWish: 'Créer un nouveau souhait',
    delete: 'Êtes-vous sûr de vouloir supprimer?',
    create: 'Créer un souhait',
    saveChanges: 'Sauvegarder les modifications',
    cancel: 'Annuler',
  },
  premium: {
    upgrade: 'Mettez à niveau vers Premium pour un accès illimité!',
    upgrade1: 'Mettez à niveau vers Premium pour un accès illimité à notre service. Cliquez sur le',
    upgrade2: 'bouton ci-dessous pour être le premier informé lorsque nos offres premium seront disponibles. Merci pour votre soutien continu.',
    notify: 'Me notifier',
  },
  message: {
    comingSoon: 'Merci, vous serez bientôt informé!',
    tryLater: 'veuillez réessayer plus tard!',
    waitMoment: 'veuillez patienter un instant!',
    wentWrong: `Quelque chose s'est mal passé, réessayez plus tard!`,
    premium: "Veuillez passer à Premium !",
    invalidLink: 'lien non valide!',
    createWish: `Création d'un nouveau vœu réussie !`,
    updateWish: 'Mise à jour du vœu réussie !',
    updateReserve: 'Réservation du vœu réussie !',
    removeWish: 'supprimé avec succès.',
    noSupport: 'Non supporté!',
    offline: 'vous êtes hors ligne!',
    updateEvent: 'Mise à jour réussie!',
    createEvent: 'Création réussie!',
    isReserved: 'Désolé, vous ne pouvez pas modifier cet événement.',
  },
};

export default fr;
